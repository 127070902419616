import type { IField } from '~/types/collection'
import type { Dictionary } from '~/types/dictionary'

const toast = useToast()

export default (collection: string) => {
  const state = useState<Dictionary>(`dictionary_${collection}`, () => ({
    loading: true,
    items: [],
    filter: null
  }))

  const actions = {
    async fetchItems (path: string) {
      if (state.value.items.length) {
        return [null, state.value.items]
      }

      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path: `/dictionary${path}`,
        query: {
          ...state.value.filter
        }
      })

      if (error && error.response._data.message) {
        toast.add({
          title: 'Ошибка',
          description: error.response._data.message,
          color: 'red',
          icon: 'i-heroicons-exclamation-circle-20-solid'
        })
      }
      if (data) {
        mutations.setItems(data.items)
      }

      mutations.setLoading(false)

      return [error, data]
    },

    setFilter ({ field, value }: IField) {
      mutations.setFilterValue({ field, value })
    },

    resetItems () {
      mutations.setItems([])
    },

    reset () {
      mutations.setLoading(true)
      mutations.resetFilter()
      actions.resetItems()
    }
  }

  const mutations = {
    setLoading (loading: boolean) {
      state.value.loading = loading
    },

    setItems (items: IField[]) {
      state.value.items = items
    },

    setFilterValue ({ field, value }: IField) {
      state.value.filter = {
        ...state.value.filter,
        [field]: value
      }
    },

    resetFilter () {
      state.value.filter = {}
    }
  }

  return {
    state,
    actions,
    mutations
  }
}
